import React from 'react'
import './src/fonts/fonts.css'

import { ThemeProvider } from './src/theme/Provider'
import { ReactQueryProvider } from './src/providers/ReactQuery'

export const wrapRootElement = ({ element }) => (
  <ReactQueryProvider>
    <ThemeProvider>{element}</ThemeProvider>
  </ReactQueryProvider>
)
