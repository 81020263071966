import React from 'react'
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
  CssBaseline,
  menuClasses,
} from '@mui/material'
import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'
import { transition } from '@cloudinary/url-gen/actions/effect'

const BLACK = '#101117'

const theme = createTheme({
  ...adaptV4Theme({
    palette: {
      neutral: {
        gray: '#737373',
        mediumGray: '#9D9D9D',
        lightGray: '#E5E5E5',
        secondaryBackground: '#FFF9F3',
      },
      common: {
        black: BLACK,
      },
      primary: {
        main: '#209F5B',
        light: '#23CB6E',
      },
      secondary: {
        main: '#209F5B',
      },
      success: {
        main: '#3AB47E',
      },
      info: {
        main: '#509FF4',
      },
      warning: {
        main: '#F9D724',
      },
      error: {
        main: '#FF4D4D',
      },
      background: {
        default: '#fff',
      },
      text: {
        primary: '#262626',
      },
    },
    app: {
      neon: '#67FF64',
      green: '#C5E9C7',
      yellow: '#F9D99E',
      purple: '#DAC2E8',
      primaryGradient:
        'linear-gradient(109.62deg, #FC642D 16.67%, #FFC371 108.83%)',
      secondaryGradient: BLACK,
    },
  }),
  typography: {
    fontFamily: [
      'CommunityGothic',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    t1: {
      fontSize: '5.375rem',
      fontWeight: 900,
      lineHeight: 1.25,
    },
    t2: {
      fontSize: '1rem',
      fontWeight: 600,
      lineHeight: 1.25,
      textTransform: 'uppercase',
      fontFamily: 'RobotoMono',
    },
    h1: {
      fontSize: '2.25rem',
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 600,
      lineHeight: 1.25,
    },
    h3: {
      fontSize: '1.5rem',
      fontWeight: 600,
      lineHeight: 1.34,
    },
    h4: { fontSize: '1.25rem', fontWeight: 600, lineHeight: 1.25 },
    h5: { fontSize: '1rem', fontWeight: 600, lineHeight: 1.25 },
    h6: { fontSize: '0.875rem', fontWeight: 600, lineHeight: 1.25 },
    body2: { fontSize: '1.25rem', fontWeight: 300, lineHeight: 1.25 },
    body3: { fontWeight: 300, fontSize: '1rem', lineHeight: 1.25 },
    body4: { fontWeight: 400, fontSize: '0.875rem', lineHeight: 1.25 },
    button: {
      fontSize: '1.125rem',
      fontWeight: 400,
      lineHeight: 1.22,
      fontFamily: 'RobotoMono',
    },
    caption1: { fontWeight: 600, fontSize: '0.75rem', lineHeight: 1.25 },
    caption2: { fontWeight: 600, fontSize: '0.625rem', lineHeight: 1.25 },
    body1: {},
    caption: {},
    subtitle2: {},
    overline: {},
  },
  mixins: {},
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        'html, body, h1, h2, h3, p, ul': { margin: 0, padding: 0 },
        'html, body, #root': { width: '100%', height: '100%' },
        button: {
          fontFamily: 'inherit',
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: { height: 8, borderRadius: 5 },
        bar: { borderRadius: 5 },
      },
    },
    MuiTab: { styleOverrides: { root: { opacity: 1 } } },
    MuiRadio: {
      defaultProps: { color: 'secondary', sx: { color: 'secondary.main' } },
    },
    MuiButton: {
      defaultProps: { disableElevation: true, variant: 'contained' },
      styleOverrides: {
        sizeSmall: ({ theme }) => ({
          fontSize: '1rem',
          [theme.breakpoints.down('md')]: {
            fontSize: '0.875rem',
          },
        }),
        text: ({ ownerState }) => ({
          whiteSpace: 'nowrap',
          color: theme.palette.common.white,
          padding: ownerState.size === 'large' ? '16px 20px' : undefined,
          background: 'none',
          '&:hover, &:focus-visible': {
            background: 'none',
            // backgroundClip: 'text',
            color: theme.palette.neutral.mediumGray,
          },
        }),
        outlined: ({ ownerState, theme }) => ({
          whiteSpace: 'nowrap',
          transition: 'none',
          padding: ownerState.size === 'large' ? '15px 19px' : '8px 15px',
          background: theme.palette.common.white,
          color: theme.palette.common.black,
          borderColor: theme.palette.neutral.mediumGray,
          borderWidth: 1,
          borderRadius: 2,
          '&:hover, &:focus-visible': {
            borderColor: theme.palette.primary.main,
            background: '#F3F9F6',
          },
        }),
        contained: ({ ownerState, theme }) => ({
          whiteSpace: 'nowrap',
          transition: 'none',
          borderRadius: 2,
          border:
            ownerState.color === 'secondary'
              ? `1px solid ${theme.palette.neutral.lightGray}`
              : `1px solid ${theme.palette.common.black}`,
          background:
            ownerState.color === 'primary'
              ? theme.palette.common.black
              : theme.palette.neutral.secondaryBackground,
          color:
            ownerState.color === 'secondary'
              ? theme.palette.common.black
              : undefined,
          padding: ownerState.size === 'large' ? '16px 20px' : '9px 16px',
          '&:hover, &:focus-visible': {
            borderColor: theme.app.neon,
            transition: theme.transitions.create([
              'background-color',
              'color',
              'border-color',
            ]),
            backgroundColor: theme.app.neon,
            color: theme.palette.common.black,
          },
        }),
      },
    },
    MuiPaper: {
      defaultProps: { elevation: 0 },
      styleOverrides: { root: { boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.4)' } },
    },
    MuiAppBar: { styleOverrides: { root: { boxShadow: 'none' } } },
    MuiList: {
      styleOverrides: { root: { minWidth: 250 } },
      defaultProps: { dense: true },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          padding: 0,
        },
        paper: ({ theme }) => ({
          border: `1px solid ${theme.palette.common.black}`,
          padding: 20,
          boxShadow: 'none',
        }),
        root: ({ theme }) => ({
          padding: 2,
        }),
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: ({ theme }) => ({
          ...theme.typography.button,
          padding: 8,
          backgroundClip: 'content-box',
          '&:hover': {
            backgroundClip: 'content-box',
            backgroundColor: theme.app.neon,
          },
          //   background: theme.palette.neutral.secondaryBackground,
          // },
          // '&.Mui-selected, &.Mui-selected:hover': {
          //   background: `${theme.palette.neutral.secondaryBackground} !important`,
          // },
        }),
      },
    },
    MuiLink: {
      defaultProps: { underline: 'hover', color: 'secondary.main' },
    },
    MuiButtonBase: {
      defaultProps: { disableRipple: true, disableTouchRipple: true },
    },
    MuiSwitch: { defaultProps: { disableFocusRipple: true } },
  },
})

/**
 * Responsive type sizes
 */
theme.typography.h1 = {
  ...theme.typography.h1,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.6rem',
  },
}
theme.typography.h2 = {
  ...theme.typography.h2,
  [theme.breakpoints.down('md')]: {
    fontSize: '1.4rem',
  },
}
theme.typography.h3 = {
  ...theme.typography.h3,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}
theme.typography.h4 = {
  ...theme.typography.h4,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}
theme.typography.body2 = {
  ...theme.typography.body2,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}
theme.typography.button = {
  ...theme.typography.button,
  [theme.breakpoints.down('md')]: {
    fontSize: '1rem',
  },
}
export const ThemeProvider: React.FunctionComponent<{
  children: React.ReactNode
}> = ({ children }) => {
  return (
    <StyledEngineProvider injectFirst>
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <StyledComponentsThemeProvider theme={theme}>
          {children}
        </StyledComponentsThemeProvider>
      </MuiThemeProvider>
    </StyledEngineProvider>
  )
}
